import { useContext } from 'react';

import { Container } from 'containers/Layout';
import { ThemeContext } from 'theme';

export default function Custom404() {
  const context = useContext(ThemeContext);
  const isAuthenticate = context?.isAuthenticate;
  return (
    <Container
      isSidebar={isAuthenticate}
      meta={{
        title: 'Not available 404',
        ogUrl: ``,
        description: '',
        ogImage: '',
      }}
    >
      <h2>404 - Page Not Found!</h2>
    </Container>
  );
}
